class MainPageManager{

  constructor() {

    this.initMobileMenu();
  }

  initMobileMenu(){
    this.dombody = document.getElementsByTagName("body")[0];
    this.mobilebtn = document.getElementById("mobileMenuButton");
    this.mobileMenuView = document.getElementById("mobileMenu");

    this.mobilebtn.addEventListener("click",() => {
      this.openMobileMenu(!this.mobilebtn.classList.contains("active"));
    })

    window.addEventListener("resize",() => {
      this.openMobileMenu(false);
    })
  }

  openMobileMenu(open=true){
    if(open){
      console.log(this.dombody);
      this.mobilebtn.classList.add("active");
      this.mobileMenuView.classList.add("active");
      this.dombody.classList.add("mobile_menu_open");
    } else {
      this.mobilebtn.classList.remove("active");
      this.mobileMenuView.classList.remove("active");
      this.dombody.classList.remove("mobile_menu_open");
    }

  }

}


export default MainPageManager;
