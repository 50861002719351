import React, { useEffect } from "react"
import Header from "../layout/header"
import Footer from "../layout/footer";
import MobileMenu from "../components/MobileMenu"
import MainPageManager from "../../dynamic/main";
import {Helmet} from "react-helmet";

const BaseLayout = (props) => {

  const { children, context} = props

  const classSlug = context.slug.replace("/","_");

  useEffect(() => {
    new MainPageManager();
  },[])

  return (
    <div id={"document"} className={`page${classSlug}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FLEX ELEKTRO !</title>

      </Helmet>
      <Header/>
      <div id="content">{children}</div>
      <Footer/>
      <MobileMenu/>
    </div>
  )

}

export default BaseLayout
