import React from "react";
import MainNav from "./MainNav"

const MobileMenu = (props) => {


  return (

    <div id="mobileMenu">
      <div className="inner">
        <MainNav/>
      </div>
    </div>

  )
}

export default MobileMenu;
