import React from "react"
import { Link } from "gatsby"

const Footer = (props) => {

  return (

    <div id={"footer"}>
      <div className="inner w-100 d-flex flex-row">

        <div className="w-50 w-md-33 m-b-2">
          <strong>Kontakt</strong>
          <br/>
          Felix Deimling<br/>
          Kanzowstrasse 1<br/>
          10439 Berlin<br/>
          -------------------------<br/>
          mail: mail@felixdeimling.de<br/>
          phone: 0176 / 61568641<br/>
        </div>
        <div className="w-50 w-md-33 m-b-2 ">
          <div>Twitter</div>
          <div>Github</div>
          <div>Linkedin</div>
          <div>XING</div>
        </div>
        <div className="w-100 w-md-33 m-b-2">
          <Link to={"/impressum"}>Impressum</Link>
          <br/>
          <Link to={"/contact"}>Kontakt</Link>
          <br/>
          <Link to={"/datenschutz"}>Datenschutz</Link>
        </div>

      </div>
    </div>

  )
}

export default Footer
