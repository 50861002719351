import React from "react"
import { Link } from "gatsby"
import MainNav from "../components/MainNav"

const Header = (props) => {


  return (

    <div id="header">
      <div className="header_inner">

        <div className="logo" id="logo"><span>FLEXELEKTRO</span></div>
        <MainNav/>
        <div id="mobileMenuButton" className="mobileMenuButton">
          <div/>
          <div/>
          <div/>
        </div>
      </div>
    </div>

  )
}

export default Header
