import React from "react"
import { Link } from "gatsby"

const MainNav = (props) => {



  return (
    <div className={"main_navigation"}>
      <div className={"menu_item"}>
        <Link activeClassName="active" to={"/"}>Start</Link>
      </div>
      <div className={"menu_item"}>
        <Link partiallyActive activeClassName="active" to={"/info"}>Info</Link>
      </div>
      <div className={"menu_item"}>
        <Link partiallyActive activeClassName="active" to={"/showcase"}>Showcase</Link>
      </div>
      <div className={"menu_item"}>
        <Link partiallyActive activeClassName="active" to={"/post"}>Blog</Link>
      </div>
      <div className={"menu_item"}>
        <Link partiallyActive activeClassName="active" to={"/contact"}>Kontakt</Link>
      </div>
    </div>
  )
}

export default MainNav;
